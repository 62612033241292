<template>
	<template v-if="info.list.qc.cr_id">
		<div class="lf_course_banner_box" v-if="info.list&&info.list.qc&&info.list.qc.cr_show_ditu">
			<img class="lf_course_banner_" :src="info.list.qc.cr_show_ditu">
		</div>
		<div class="all_body">
			<div class="lf_match_info_box">
				<div class="d_title">{{langs.qcjs_qcgl}} | <span>{{langs.qcjs_qcgl_en}}</span></div>
			</div>
		</div>
		<div class="all_body">
			<div class="body" style="padding-bottom: 0.2rem;">
				<div class="boxbox">
					<div class="bodybox">
						<div class="bodybox1">
							{{langs.qcjs_qcmc}}
						</div>
						<div class="bodybox2">
							{{langs.qcjs_bzg}}
						</div>
						<div class="bodybox3">
							{{langs.qcjs_zcd}}
						</div>
						<div class="bodybox4">
							{{langs.qcjs_qcdd}}
						</div>
						<div class="bodybox5">
							{{langs.qcjs_qcgw}}
						</div>
					</div>
					<div class="bodytwo">
						<div class="bod1 bold">
							{{info.list?info.list.qc.cr_name:''}}
						</div>
						<div class="bod2 bold">
							{{info.list?info.list.qc.par:''}}
						</div>
						<div class="bod3 bold">
							{{info.list&&info.list.jl&&info.list.jl!=0?info.list.jl:'-'}}{{info.list&&info.list.jl&&info.list.jl!=0?(systemlang=="cn"?"码":"yds"):""}}
						</div>
						<div class="bod4">
							{{info.list?info.list.qc.cr_address:''}}
						</div>
						<div class="bod5">
							{{info.list?info.list.qc.cr_url:''}}
						</div>
					</div>
				</div>
				<div class="boxbox1">
					<div class="bodyend">
						<div class="boxend">
							{{langs.qcjs_qcmc}}
						</div>
						<div class="boxend1">
							{{langs.qcjs_bzg}}
						</div>
						<div class="boxend2">
							{{langs.qcjs_zcd}}
						</div>
					</div>
					<div class="bodytwo">
						<div class="bod1">
							{{info.list?info.list.qc.cr_name:''}}
						</div>
						<div class="bod2">
							{{info.list?info.list.qc.par:''}}
						</div>
						<div class="bod3">
							{{info.list&&info.list.jl&&info.list.jl!=0?info.list.jl:'-'}}{{info.list&&info.list.jl&&info.list.jl!=0?(systemlang=="cn"?"码":"yds"):""}}
						</div>
					</div>
					<div class="endadd">
						<div class="addre">
							<div class="addressbox">
								{{langs.qcjs_qcdd}}
							</div>
							<div class="addressbox1">
								{{info.list?info.list.qc.cr_address:''}}
							</div>
						</div>
						<div class="addre">
							<div class="addressbox">
								{{langs.qcjs_qcgw}}
							</div>
							<div class="addressbox1">
								{{info.list?info.list.qc.cr_url:''}}
							</div>
						</div>
					</div>
				</div>
				<div class="ends">
					<div class="endbox">
						<div class="boxnum hand">
							<div class="box1">
								Hole
							</div>

							<div class="box2" @click="changehole(index)" v-if="info.list&&info.list.qcd"
								v-for="(b,index) in info.list.qcd">
								{{b.mh_id}}
								<img src="../../assets/images/u7110.svg" v-if="index==holeindex" />
							</div>
						</div>
						<div class="boxnum hand">
							<div class="box1">
								Par
							</div>
							<div class="box3" @click="changehole(index)" v-if="info.list&&info.list.qcd"
								v-for="(b,index) in info.list.qcd">
								{{b.mh_par}}
							</div>
						</div>
						<div class="boxnum hand">
							<div class="box1">
								Yards
							</div>
							<div class="box3 black" @click="changehole(index)" v-if="info.list&&info.list.qcd"
								v-for="(b,index) in info.list.qcd">
								{{b.mh_yds?b.mh_yds:"-"}}
							</div>
						</div>
					</div>
				</div>

				<div class="tablebox">
					<div class="tablehead">
						<swiper :modules="modules" :autoplay="false" :pagination="{ clickable: true }"
							@swiper="onSwiper" @slideChange="onSlideChange" class="swiper_all">
							<!-- 1-9 -->
							<swiper-slide class="swiper_item" v-if="info.list&&info.list.qcd" v-for="area in info.list.qcd.length/9">
								<div class="pink_body">
									<div class="white_body">
										<el-row>
											<el-col :xs="24" :sm="24" :md="24">
												<div class="lf_match_info_con">
													<div class="lf_221">Hole</div>
													<template v-if="info.list&&info.list.qcd" v-for="(b,index) in info.list.qcd">
														<div class="lf_22 redColor " @click="changehole(index)" v-if="index>=(area-1)*9&&index<area*9">
															{{b.mh_id}}
															<img class="lf_border_img" src="../../assets/images/u7110.svg" v-if="index==holeindex" />
														</div>
													</template>
												</div>
												<div class="lf_match_info_score_con">
													<div class="lf_221">Par</div>
													<template v-if="info.list&&info.list.qcd" v-for="(b,index) in info.list.qcd">
														<div class="lf_22 " @click="changehole(index)" v-if="index>=(area-1)*9&&index<area*9">
															{{b.mh_par}}
														</div>
													</template>
												</div>
												<div class="lf_match_info_score_con">
													<div class="lf_221">Yards</div>
													<template v-if="info.list&&info.list.qcd" v-for="(b,index) in info.list.qcd">
														<div class="lf_22 " @click="changehole(index)" v-if="index>=(area-1)*9&&index<area*9">
															{{b.mh_yds?b.mh_yds:"-"}}
														</div>
													</template>
												</div>
											</el-col>
										</el-row>
									</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>

				<div class="hoold lf_hoold">

					<div class="ho1">
						<div class="hotitle">
							<div class="hobox1" v-if="info.list&&info.list.qcd">
								Hole {{info.list.qcd[holeindex].mh_id}}
							</div>
							<div class="hobox2" v-if="info.list&&info.list.qcd">
								Par {{info.list.qcd[holeindex].mh_par}}
							</div>
							<div class="hobox2" v-if="info.list&&info.list.qcd">
								{{info.list.qcd[holeindex].mh_yds&&info.list.qcd[holeindex].mh_yds!=0?info.list.qcd[holeindex].mh_yds:""}}{{info.list.qcd[holeindex].mh_yds!=0?(systemlang=="cn"?"码":"yds"):""}}
							</div>
						</div>

						<div class="hobody" style="white-space: pre" v-if="holeInfo&&holeInfo.hc_content">
							{{holeInfo.hc_content}}
						</div>
						<div class="hobody" v-else>
							<span v-if="systemlang == 'cn'" style="color:#666666;">暂无相关内容，先看看别的吧~</span>
							<span v-if="systemlang != 'cn'" style="color:#666666;">There's no relevant info~</span>
						</div>
					</div>
					<div class="ho2">
						<img :src="holeInfo.url" v-if="holeInfo&&holeInfo.url" />
						<img src="../../assets/images/u7084.png" v-else />
					</div>
				</div>
				<div class="honow">
					<div class="ho3">
						<div class="hotitle">
							<div class="hobox1" v-if="info.list&&info.list.qcd">
								Hole {{info.list.qcd[holeindex].mh_id}}
							</div>
							<div class="hobox2" v-if="info.list&&info.list.qcd">
								Par {{info.list.qcd[holeindex].mh_par}}
							</div>
							<div class="hobox2" v-if="info.list&&info.list.qcd">
								{{info.list.qcd[holeindex].mh_yds&&info.list.qcd[holeindex].mh_yds!=0?info.list.qcd[holeindex].mh_yds:""}}{{info.list.qcd[holeindex].mh_yds!=0?(systemlang=="cn"?"码":"yds"):""}}
							</div>
						</div>

						<div class="hobody" style="white-space: pre" v-if="holeInfo&&holeInfo.hc_content">
							{{holeInfo.hc_content}}
						</div>
						<div class="hobody" v-else>
							<span v-if="systemlang == 'cn'" style="color:#666666;">暂无相关内容，先看看别的吧~</span>
							<span v-if="systemlang != 'cn'" style="color:#666666;">There's no relevant info~</span>
						</div>
					</div>
					<div class="ho4">
						<img :src="holeInfo.url" v-if="holeInfo&&holeInfo.url" />
						<img src="../../assets/images/u7084.png" v-else />
					</div>

				</div>
			</div>
			<div class="guanggao" v-show="bannershow && bannershow['COURSE_CENTER'] == '1'">
				<banner heightNew="1.85rem" :type="2" abs="COURSE_CENTER"></banner>
			</div>
			<div class="lf_match_info_box lf_match_info_box_top">
				<div class="d_title">{{langs.qcjs_dbwz}} | <span>{{langs.qcjs_dbwz_en}}</span></div>
			</div>
			<div class="lf_match_info_box lf_match_radio_info_box">
				<div class="lf_group_tit_left">
					<el-radio-group v-model="rd_id" @change="getholeposition">
						<el-radio :label="item" v-for="(item,index) in roundarr">{{langs.qcjs_di}}{{ziarr[item]}}{{langs.qcjs_lun}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="end">
				<div class="endbox">
					<div class="boxnum">
						<div class="box1">
							Hole
						</div>
						<div class="box2" v-for="(item,index) in holeposition">
							{{item.hole}}
						</div>
					</div>
					<div class="boxnum">
						<div class="box1">
							Front
						</div>
						<div class="box3" v-for="(item,index) in holeposition">
							{{item.front}}
						</div>
					</div>
					<div class="boxnum">
						<div class="box1">
							Side
						</div>
						<div class="box3" v-for="(item,index) in holeposition">
							{{item.side}}
						</div>
					</div>
				</div>
			</div>


			<div class="tablebox lf_pink_body_box">
				<div class="tablehead">
					<swiper :modules="modules" :autoplay="false" :pagination="{ clickable: true }"
						@swiper="onSwiper" @slideChange="onSlideChange" class="swiper_all">
						<swiper-slide class="swiper_item" v-for="area in holeposition.length/9">
							<div class="pink_body lf_pink_body">
								<div class="white_body">
									<el-row>
										<el-col :xs="24" :sm="24" :md="24">
											<div class="lf_match_info_con">
												<div class="lf_221">Hole</div>
												<template v-for="(item,index) in holeposition">
													<div class="lf_22 redColor " v-if="index>=(area-1)*9&&index<area*9">
														{{item.hole}}
													</div>
												</template>
											</div>
											<div class="lf_match_info_score_con">
												<div class="lf_221">Front</div>
												<template  v-for="(item,index) in holeposition">
													<div class="lf_22 " v-if="index>=(area-1)*9&&index<area*9">
														{{item.front}}
													</div>
												</template>
											</div>
											<div class="lf_match_info_score_con">
												<div class="lf_221">Side</div>
												<template  v-for="(item,index) in holeposition">
													<div class="lf_22" v-if="index>=(area-1)*9&&index<area*9">
														{{item.side}}
													</div>
												</template>
											</div>
										</el-col>
									</el-row>
								</div>
							</div>
						</swiper-slide>
						
					</swiper>
				</div>
			</div>
		</div>
	</template>
	<div v-else>
		<empty></empty>
	</div>
</template>


<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import banner from "../../components/banner.vue";
	import empty from "../../components/empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'
	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				info: {},
				holeindex: 0,
				rd_id: "",
				roundarr: [],
				ziarr: [],
				holeposition: [],
				bannershow: {},
				holeInfo: {}
			}
		},
		props: {
			mt_id: String
		},
		watch: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			banner,
			empty
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.ziarr = this.langs.qcjs_sz;
			axios('matchs/match/matchtj.json', {
					otp: 'course',
					mt_id: this.mt_id
				})
				.then((res) => {
					let data = res.data.data;
					this.info = data;
					this.rd_id = data.info.mt_round;
					this.getholeposition();
					var roundarr = [];
					for (var i = 1; i <= data.info.mt_round_num; i++) {
						roundarr.push(i)
					}
					this.roundarr = roundarr;
					this.changehole(0)
				});
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				// console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: true,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {
			changehole(index) {
				// this.holeInfo = {}
				this.holeindex = index;
				var hole = this.info.list.qcd[index].mh_id;
				axios('matchs/match/matchtj.json', {
						otp: 'holeInfo',
						mt_id: this.mt_id,
						hole: hole
					})
					.then((res) => {
						let data = res.data.data.list;
						this.holeInfo = data;
					})
					.catch((error) => {
    					console.log(error);
						this.holeInfo = {}
  					});
			},
			changeround(rd_id) {
				this.rd_id = rd_id;
				this.getholeposition();
			},
			getholeposition() {
				this.holeposition = [];
				for (var i = 1; i <= 18; i++) {
					this.holeposition.push({
						hole: i,
						front: "",
						side: ""
					})
				}
				axios('matchs/match/matchtj.json', {
						otp: 'holeposition',
						mt_id: this.mt_id,
						rd_id: this.rd_id
					})
					.then((res) => {
						let data = res.data.data.list;
						if (data.length > 0) {
							this.holeposition = data;
						}
					});
			},
			setbannershow(abs, isshow) {
				this.bannershow[abs] = isshow;
			}
		}

	}
</script>


<style scoped="scoped" lang="less">
	.lf_group_tit_left {
		display: flex;
		align-items: center;
		margin-top: 0.2rem;
	}

	.tablebox {
		display: none;
	}

	.hoold {
		display: block;
	}
	.lf_hoold{
		overflow: hidden;
		background-color: rgba(255, 105, 116, 0.1);
		width: 17.9rem;
		margin: 0 auto;
	}
	.honow {
		display: none;
	}
.lf_course_banner_box{
		width: 18.5rem;
		height: 4rem;
		margin: 0.5rem auto 0.2rem;
	}
	.lf_course_banner_{
		width: 18.5rem;
		height: 4rem;
		margin: 0 auto;
	}

	.ho1 {
		width: 10.5rem;
		height: 8rem;
		margin-left: 0.8rem;

		float: left;
	}


	.hotitle {
		width: 8rem;
		height: 1rem;
		line-height: 1rem;

	}

	.hobox1 {
		color: #C0153E;
		font-size: 0.46rem;
		font-weight: bold;
		float: left;
		text-align: center;
	}

	.hobox2 {
		color: #000000;
		font-size: 0.32rem;
		float: left;
		text-align: center;
		margin-left: 0.6rem;
	}

	.hobody {
		clear: both;
		width: 10.5rem;

		line-height: 0.5rem;
		margin-top: 0.3rem;
		font-size: 0.28rem;
		color: #333333;
		overflow: hidden;
	}

	.hobody1 {
		clear: both;
		width: 10.5rem;

		line-height: 0.5rem;
		margin-top: 0.3rem;
		font-size: 0.28rem;
		color: #333333;
		overflow: hidden;
	}

	.ho2 {
		width: 4.04rem;
		height: 7.2rem;
		float: left;
		margin-left: 1.2rem;
	}

	.ho2 img {
		width: 4.04rem;
		height: 7.2rem;
	}

	.bodybox1 {
		width: 3.5rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
		float: left;
	}

	.bodybox2 {
		width: 2rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
		float: left;
	}

	.bodybox3 {
		width: 2.5rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
		float: left;
	}

	.bodybox4 {
		width: 4.5rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
		float: left;
	}

	.bodybox5 {
		width: 4.9rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
		float: left;
	}

	.bod1 {
		width: 3.5rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		float: left;

	}

	.bod2 {
		width: 2rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		float: left;
	}

	.bod3 {
		width: 2.5rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		float: left;
	}

	.bod4 {
		width: 4.5rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		float: left;
	}

	.bod5 {
		width: 4.9rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		float: left;
	}

	.bodytwo {
		width: 18.5rem;
		height: fit-content;
		line-height: normal;
		overflow: hidden;
		display: flex;
		align-items: center;
		
	}

	.timu {
		width: 100%;
		text-align: center;
		font-size: 0.4rem;
		font-weight: bold;
		color: #C0153E;


	}

	.data {
		width: 100%;
		text-align: center;
		font-size: 0.24rem;
		color: #C0153E;
		margin-top: 0.1rem;
	}

	.titleima img {
		width: 18.5rem;
		height: 4rem;
		margin-left: 0.4rem;
		margin-top: 0.4rem;
	}

	.address {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 0.2rem;

	}

	.name {

		font-size: 0.24rem;
		color: #333333;
	}

	.address img {
		width: 0.26rem;
		height: 0.26rem;

	}

	.guanggao {
		width: 18.5rem;
		height: 1.85rem;

		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	
	}

	.lun {
		width: 10rem;
		height: 0.8rem;
		margin-left: 1.1rem;
	}

	.lunima img {
		width: 0.2rem;
		height: 0.2rem;
		margin-top: 0.3rem;
		float: left;
	}

	.lunimas img {
		width: 0.2rem;
		height: 0.2rem;
		margin-top: 0.3rem;
		float: left;
		margin-left: 0.4rem;
	}

	.luntext {
		float: left;
		font-size: 0.24rem;
		margin-left: 0.14rem;
		margin-top: 0.24rem;
	}

	.end {
		width: 18.5rem;
		height: 2.3rem;

		margin-top: 0.2rem;
		background-color: #FAFAFA;
		overflow: hidden;
	}

	.ends {
		width: 18.5rem;
		height: 2.3rem;

		margin-top: 0.2rem;
		background-color: #FAFAFA;
		overflow: hidden;
	}

	.endbox {
		width: 17.92rem;
		height: 1.8rem;
		margin-left: 0.28rem;
		margin-top: 0.24rem;
		background-color: #ffffff;
	}

	.box1 {
		width: 1.72rem;
		height: 0.6rem;
		line-height: 0.6rem;
		text-align: center;
		color: #666666;
		font-size: 0.24rem;
		border: 0.01rem solid #DBDBDB;
		float: left;
	}

	.box2 {
		width: 0.9rem;
		height: 0.6rem;
		line-height: 0.6rem;
		text-align: center;
		color: #C0153E;
		font-size: 0.28rem;
		border: 0.01rem solid #DBDBDB;
		float: left;
		position: relative;
	}

	.box2 img {
		position: absolute;
		left: -1px;
		top: 0;
		width: 0.9rem;
		height: 1.98rem;
		z-index: 9;
	}

	.box3 {
		width: 0.9rem;
		height: 0.6rem;
		line-height: 0.6rem;
		text-align: center;
		color: #666666;
		font-size: 0.28rem;
		border: 0.01rem solid #DBDBDB;
		float: left;
	}

	.body {
		width: 18.5rem;

		background-color: #FAFAFA;
		margin-top: 0.2rem;
		overflow: hidden;
	}

	.bodybox {
		width: 18.5rem;
		height: 0.6rem;

		border-bottom: 0.01rem solid #DBDBDB;

	}

	.boxbox1 {
		display: none;
	}

	.el-radio__label{
		color: #333333;
	}
	.body{margin-bottom: 0.2rem;}
	.lf_match_info_box_top{margin-top: 0.2rem;}

	@media screen and (max-width: 768px) {
		.ends {
			display: none;
		}

		.end {
			display: none;
		}

		.honow {
			display: block;
		}

		.hoold {
			display: none;
		}
		.lf_course_banner_box{
			width: 100%;
			height: 1.62rem;
		}
		.lf_course_banner_{
			width: 100%;
			height: 1.62rem;
		}


		.body {
			width: 7.5rem;

			background-color: #FAFAFA;
			margin-top: 0.2rem;
			overflow: hidden;
		}

		.boxbox {
			display: none;
		}

		.boxbox1 {
			display: block;
			width: 7.5rem;
			height: fit-content;
			border-bottom: 0.01rem solid #DBDBDB;
			padding-bottom: 0.1rem;
		}

		.bodybox {
			width: 18.5rem;
			height: 0.6rem;

			border-bottom: 0.01rem solid #DBDBDB;

		}

		.bodyend {
			width: 6.75rem;
			height: 0.6rem;
			margin-left: 0.375rem;
			border-bottom: 0.01rem solid #DBDBDB;
		}

		.boxend {
			width: 2.65rem;
			height: 0.6rem;
			line-height: 0.6rem;
			font-size: 0.24rem;
			color: #666666;
			text-align: center;
			float: left;
		}

		.boxend1 {
			width: 2.2rem;
			height: 0.6rem;
			line-height: 0.6rem;
			font-size: 0.24rem;
			color: #666666;
			text-align: center;
			float: left;
		}

		.boxend2 {
			width: 1.9rem;
			height: 0.6rem;
			line-height: 0.6rem;
			font-size: 0.24rem;
			color: #666666;
			text-align: center;
			float: left;
		}

		.bod1 {
			width: 2.65rem;
			color: #333333;
			font-size: 0.28rem;
			text-align: center;
			float: left;
			margin-left: 0.375rem;
		}

		.bod2 {
			width: 2.2rem;
			color: #333333;
			font-size: 0.28rem;
			text-align: center;
			float: left;
		}

		.bod3 {
			width: 1.9rem;
			color: #333333;
			font-size: 0.28rem;
			text-align: center;
			float: left;
		}

		.bodytwo {
			width: 7.5rem;
			height: fit-content;
			line-height: normal;
			display: flex;
			align-items: center;
			min-height: 0.8rem;
		}

		.endadd {
			width: 6.75rem;
			// height: 1.02rem;
			margin-left: 0.375rem;
			margin-top: 0.02rem;

		}

		.addre {
			width: 6.75rem;
			// height: 0.5rem;
			display: flex;
			align-items: center;
		}

		.addressbox {
			width: 2.22rem;
			 height: 0.7rem;
			min-height: 0.5rem;
			line-height: 0.3rem;
			color: #666666;
			font-size: 0.26rem;
			text-align: center;
			border: 0.01rem solid #DBDBDB;
			float: left;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.addressbox1 {
			width: 4.5rem;
			 height: 0.7rem;
			min-height: 0.5rem;
			line-height: 0.3rem;
			color: #333333;
			font-size: 0.26rem;
			text-align: center;
			border: 0.01rem solid #DBDBDB;
			float: left;
			background-color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
		}



		.ho3 {
			width: 6.7rem;

			margin-left: 0.4rem;


		}

		.hotitle {
			width: 6.7rem;
			height: 0.9rem;
			line-height: 0.9rem;

		}

		.hobody {
			width: 6.7rem;

			line-height: 0.5rem;
			margin-top: 0.3rem;
			font-size: 0.28rem;
			color: #333333;
		}

		.hobody1 {
			width: 6.7rem;

			line-height: 0.5rem;
			margin-top: 0rem;
			font-size: 0.28rem;
			color: #333333;
		}

		.ho4 {
			width: 7.5rem;
			height: 7.2rem;
			margin-top: 0.4rem;
			background-color: rgba(255, 105, 116, 0.1);
			display: flex;
			justify-content: center;
		}

		.ho4 img {
			width: 4.04rem;
			height: 7.2rem;
		}

		.guanggao {
			width: 7.5rem;
			height: 0.75rem;

			margin-top: 0.4rem;
			margin-bottom: 0.4rem;
		
		}


		.lf_footer1 {
			width: 100%;
			margin: 0 auto;
		}

		.lf_footer1_tit_box {
			width: 100%;
			height: 90px;
		}


		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-size: 0.26rem;
		}


		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}




		.tablebox {
			width: 7.5rem;
			overflow: hidden;
			background-color: #FAFAFA;
			display: block;
			
		}

		.tablehead {
			width: 7.1rem;
			margin-left: 0.2rem;
			overflow: hidden;


		}

		.redColor {
			color: #C0153E !important;
		}

		.blueColor {
			color: #2C2AE3 !important;
		}

		.pinkColor {
			background-color: #FFF1F2 !important;

		}

		.swiper_all {
			width: 100%;
			height:2.9rem;
			overflow: hidden;

		}

		.swiper_item {
			width: 100%;
			height: auto;
		}

		.pink_body {
			width: 6.8rem;
			height: 2.7rem;
			background-color: #FFF1F2;
			border-radius: 0.08rem;
			margin: 0 auto;
			margin-top: 0.24rem;
			overflow: hidden;

		}

		.white_body {
			width: 6.4rem;
			background-color: #fff;
			border-radius: 0.08rem;
			margin: 0 auto;
			margin-top: 0.2rem;
		}







		.lf_match_info_con {
			display: flex;
			box-sizing: border-box;
			border-bottom: 0.01rem solid #EFEFEF;
		}


		.lf_match_info_con div {
			display: flex;
			align-items: center;
			justify-content: center;

			font-family: '微软雅黑';

			font-style: normal;
			font-size: 0.24rem;
			color: #333;
			height: 0.6rem;
		}


		.lf_match_info_con .lf_221 {
			color: #666 !important;
			border-right: 1px solid #efefef;
			width: 1.32rem;
		}


		.lf_match_info_con .lf_21,
		.lf_match_info_con .lf_22 {
			width: 0.6rem;
			position: relative;
			color: #C0153E;
			flex-grow: 1;
			flex-shrink: 0;
			border-right: 1px solid #EFEFEF;
		}

		.lf_22 img {
			position: absolute;
			left: 0;
			top: 0;
			width: 0.6rem;
			height: 1.98rem;
			z-index: 10;
		}

		.lf_match_info_con .lf_22 {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
			width: 0.6rem;
			font-size: 0.28rem;
		}


		.lf_match_info_score_con {
			display: flex;
			box-sizing: border-box;
			border-bottom: 1px solid #efefef;
		}


		.lf_match_info_score_con div {
			display: flex;
			align-items: center;
			justify-content: center;

			font-family: '微软雅黑';

			font-style: normal;
			font-size: 0.28rem;
			color: #333;
			height: 0.6rem;
			border-right: 1px solid #efefef;
		}

		.lf_match_info_score_con .lf_221 {
			color: #666;
			width: 1.32rem;

		}
		.lf_match_radio_info_box{
			margin: 0 0.2rem;
		}

		.lf_match_info_score_con .lf_21,
		.lf_match_info_score_con .lf_22 {
			width: 0.6rem;
			height: 0.6rem;
			flex-grow: 1;
			flex-shrink: 0;
			color: #666;
		}
		.lf_match_info_score_con:nth-last-of-type(1) .lf_22{
			color: #333;
		}
		.lf_pink_body .lf_match_info_score_con:nth-last-of-type(2) .lf_22{
			color: #333;
		}
		.tablehead {

			// 指示灯高度
			:deep(.el-carousel .el-carousel__indicators--horizontal) {
				height: 0.5rem;

			}

			// 指示灯未选中的样式
			:deep(.el-carousel__indicator--horizontal .el-carousel__button) {
				width: 0.14rem;
				height: 0.14rem;
				border-radius: 0.14rem;
				background: #fff;
				border: rgba(153, 153, 153) 0.01rem solid;
			}

			// 指示灯选中的样式
			:deep(.el-carousel__indicator--horizontal.is-active .el-carousel__button) {
				width: 0.14rem;
				width: 0.14rem;
				width: 0.14rem;
				background: #C0153E;
				border: #C0153E 0.01rem solid;
			}
		}
		.swiper .swiper-slide .lf_22 .lf_border_img{
			height: 1.98rem!important;
		}
		.lf_pink_body_box .swiper-pagination {
				padding-left: 0!important;
			}
	}
</style>
<style >
	.swiper .swiper-slide {
		height: auto !important;
		width: 100% !important;
	
	}
	
	.swiper .swiper-slide img {
		height: auto !important;
		width: 100% !important;
	}
	
	.swiper-pagination {
		text-align: left;
		bottom: 1.5rem !important;
		padding-left: 0.5rem;
	}
	
	.swiper-pagination-bullet {
		background-color: #fff;
		border: #DBDBDB 0.01rem solid;
		opacity: 1;
		width: 0.15rem;
		height: 0.15rem;
		margin-right: 0.02rem !important;
	}
	
	@media screen and (max-width: 768px) {
			.swiper-pagination {
				display: flex;
				align-items: center;
				justify-content: center;
				bottom: 0.2rem !important;
				padding-left: 0!important;
			}
			
	}
</style>